import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, ButtonProps } from '@/components/ui';

export const LoginButton = (props: ButtonProps) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button variant="outline" onClick={() => loginWithRedirect()} {...props}>
      Se connecter
    </Button>
  );
};
