import { useState } from 'react';
import Select from 'react-select';
import { api } from '@/lib';
import { Field } from '@/components/ui';
import { toast, ToastMessages } from '@/lib/toast';
import { IoMdThumbsUp } from 'react-icons/io';
import { Icon } from '@/components/icons';
import { MinifiedNumber } from '@/components';

interface SelectAdvertisersProps {
  values: SelectOption[];
  onChange: (pages: SelectOption[]) => void;
}

export type SelectOption = {
  value: string;
  label: string;
  likes?: number | undefined;
  followers?: number | undefined;
  verified: boolean | undefined;
};

export const SelectAdvertisers: React.FC<SelectAdvertisersProps> = ({
  values,
  onChange,
}) => {
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [error, setError] = useState<string | null>(null);

  const searchPages = (input: string) => {
    if (input) {
      api.meta
        .getAdvertiserPages(input)
        .then((response) =>
          setOptions(
            response.map((metaPage) => ({
              value: metaPage.page_id,
              label: metaPage.name,
              likes: metaPage.likes,
              followers: metaPage.instagram_followers,
              verified: metaPage.verified || metaPage.instagram_verified,
            })),
          ),
        )
        .catch(() => {
          toast(ToastMessages.ERROR_APPEARED, 'error', {
            toastId: 'api-get-advertisers',
          });
        });
    } else {
      setOptions([]);
    }
  };

  const selectPages = (value: SelectOption[]) => {
    setError(null);
    if (value.length > 10) {
      setError('Vous ne pouvez sélectionner que 10 annonceurs maximum');
    } else {
      onChange(value);
    }
  };

  const formatOptionLabel = (
    { label, likes, verified, followers }: SelectOption,
    { context }: { context: 'menu' | 'value' },
  ) => {
    if (context === 'value') {
      // simple display on selected values
      return label;
    }
    return (
      <div className="flex cursor-pointer justify-between">
        <div className="flex flex-row items-center justify-center">
          <span className="mr-2">{label} </span>
          {verified && <Icon name="verified" size="small" />}
        </div>
        <div className="flex flex-row justify-between">
          {!!followers && (
            <div className="flex items-center justify-center">
              <span className="mr-2 text-sm">
                <MinifiedNumber number={followers} />
              </span>
              <Icon name="instagram" size="small" />
            </div>
          )}
          {!!likes && (
            <div className="flex w-24 items-center justify-end">
              <span className="mr-2 text-sm">
                <MinifiedNumber number={likes} />
              </span>
              <IoMdThumbsUp color="#0866ff" size={24} />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Field
      className="col-span-1 sm:col-span-2 md:col-span-3"
      w="full"
      errorText={error}
      invalid={!!error}
    >
      <Select
        className={'h-full w-full'}
        options={options}
        onInputChange={searchPages}
        onChange={(newValue) => selectPages(newValue as SelectOption[])}
        value={values}
        placeholder={'Chercher des annonceurs'}
        isMulti={true}
        noOptionsMessage={() => "Pas d'annonceur trouvé"}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            height: '100%',
            borderColor: 'gray.200',
            fontSize: '14px',
            boxShadow: state.isFocused ? '0 0 0 2px black' : 'none',
            '&:hover': {
              borderColor: 'none',
            },
          }),
        }}
        formatOptionLabel={formatOptionLabel}
      />
    </Field>
  );
};
