import { MessageBanner } from '@/components';
import React from 'react';
import { PricingPlanCode } from '@/types';

interface DashboardInfosProps {
  subscription?: PricingPlanCode | null;
  searchesMade: number;
}

export const DashboardInfos: React.FC<DashboardInfosProps> = ({
  subscription,
  searchesMade,
}) => {
  const searchesLeft = 3 - searchesMade;
  return (
    <div className={'w-full'}>
      {searchesMade < 4 && !subscription && (
        <MessageBanner variant={'success'}>
          <p className={'text-md font-semibold'}>
            {searchesLeft > 1 &&
              `Il vous reste ${searchesLeft} recherches offertes ! ✅`}
            {searchesLeft == 1 && `Il vous reste 1 recherche offerte ! ✅`}
            {searchesLeft <= 0 && `Vous n'avez plus de recherche offerte ! ✅`}
          </p>
          <p>
            Testez ensuite nos offres d&#39;abonnements avec 7 jours d&#39;essai
            gratuit
          </p>
        </MessageBanner>
      )}
      {subscription && (
        <MessageBanner variant={'info'} hideableKey={'hideAskForFeedback'}>
          <p className={'text-lg font-semibold'}>
            Donnez nous votre avis{' '}
            <a
              className={'text-blue-600 underline'}
              href={
                'https://docs.google.com/forms/d/e/1FAIpQLSc0OPjA9ZEIRqmS3TGGhd_9krbYuOtArd7YLKDw4e7SaoMyOA/viewform?usp=sf_link'
              }
              target={'_blank'}
              rel="noreferrer"
            >
              ici
            </a>{' '}
            ! 🚀
          </p>
          <p>
            Faites-nous votre retour et profitez de 7 jours d&#39;essai
            supplémentaires sur Leadspy !
          </p>
        </MessageBanner>
      )}
    </div>
  );
};
