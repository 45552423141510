import {
  DrawerBackdrop,
  DrawerBody,
  DrawerCloseTrigger,
  DrawerContent,
  DrawerRoot,
} from '@/components/ui/drawer';

export interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

export const Drawer: React.FC<DrawerProps> = ({
  children,
  isOpen,
  onClose,
}) => {
  return (
    <DrawerRoot size="md" open={isOpen} onOpenChange={() => onClose()}>
      <DrawerBackdrop />
      <DrawerContent offset="3" rounded="md" w={500}>
        <DrawerBody spaceY={5}>{children}</DrawerBody>
        <DrawerCloseTrigger />
      </DrawerContent>
    </DrawerRoot>
  );
};
