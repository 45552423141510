import { Box, HStack, Icon, Table, Text, Image } from '@chakra-ui/react';
import { FaWpforms, FaUserGroup } from 'react-icons/fa6';
import { FiInbox, FiGlobe, FiCalendar } from 'react-icons/fi';
import { TbInfoHexagon, TbLanguage } from 'react-icons/tb';
import { IoMaleFemale } from 'react-icons/io5';
import { GiDuration } from 'react-icons/gi';

export interface TableInfoProps {
  publisherPlatforms?: string[];
  targetAges?: string[];
  targetGender?: string;
  language?: string[];
  deliveryStartTime?: Date;
  deliveryStopTime?: Date;
  formType?: string;
  endDate?: number;
  websiteUrl?: string | undefined;
  isActive?: boolean;
  durationDays?: number;
}

export const TableInfo: React.FC<TableInfoProps> = (tableInfo) => {
  const items = data(tableInfo);
  return (
    <Box borderRadius="md" border="solid gray 1px" padding={5}>
      <Table.Root size="sm">
        <Table.Body>
          {items.map((item) => (
            <Table.Row key={item.id}>
              <Table.Cell>
                <HStack color="gray.400">
                  {item.icon()}
                  <Text>{item.name}</Text>
                </HStack>
              </Table.Cell>
              <Table.Cell textAlign="end">
                {item.category && item.category !== ''
                  ? item.category
                  : 'Non spécifié'}{' '}
                {item.endElement && icon(item.endElement)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </Box>
  );
};

const data = ({
  publisherPlatforms,
  targetGender,
  targetAges,
  websiteUrl,
  language,
  deliveryStartTime,
  formType,
  isActive,
  durationDays,
}: TableInfoProps) => [
  {
    id: 1,
    name: 'Website',
    category: websiteUrl ? websiteUrl : 'Non spécifié',
    icon: () => <FiInbox />,
  },
  {
    id: 2,
    name: 'Langues',
    category: language?.join(', ').toUpperCase(),
    icon: () => <TbLanguage />,
  },
  {
    id: 3,
    name: 'Plateforme',
    category: publishPRename(publisherPlatforms)?.join(', '),
    icon: () => <FiGlobe />,
  },
  {
    id: 4,
    name: "Âge d'audience",
    category: targetAges?.join(', '),
    icon: () => <FaUserGroup />,
  },
  {
    id: 5,
    name: "Genre d'audience",
    category: targetGenderRename(targetGender),
    icon: () => <IoMaleFemale />,
  },
  {
    id: 6,
    name: 'Type de formulaire',
    category: formType === 'meta' ? 'Meta' : 'Externe',
    endElement:
      formType === 'meta'
        ? '/facebook_icon.svg'
        : '/globe-with-meridians-svgrepo-com.svg',
    icon: () => <FaWpforms />,
  },
  {
    id: 7,
    name: 'Date de publication',
    category: deliveryStartTime
      ? deliveryStartTime.toLocaleDateString('fr-fr', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        })
      : '',
    icon: () => <FiCalendar />,
  },
  {
    id: 8,
    name: 'Statut',
    category: isActive ? 'Actif' : 'Arrêté',
    endElement: isActive ? '/green_circle.gif' : '/red_circle.svg',
    icon: () => <TbInfoHexagon />,
  },
  {
    id: 9,
    name: 'Temps de diffusion',
    category: durationDays !== undefined && formatDurationLong(durationDays),
    icon: () => <GiDuration />,
  },
];

const icon = (src: string) => {
  return (
    <Icon>
      <Image src={src} alt="europ-icon" width={15} height={15} />
    </Icon>
  );
};

const targetGenderRename = (targetGender: string | undefined) => {
  if (!targetGender) return '';
  return (
    {
      All: 'Tous',
      Women: 'Femme',
      Man: 'Homme',
    }[targetGender] ?? ''
  );
};

const publishPRename = (publisherPlatforms: string[] | undefined) => {
  return (
    publisherPlatforms?.map((p) =>
      (p.charAt(0).toUpperCase() + p.slice(1)).replace('_', ' '),
    ) ?? []
  );
};

const formatDurationLong = (durationInDays: number): string => {
  if (durationInDays > 365) return `${(durationInDays / 365).toFixed(0)} ans`;
  if (durationInDays > 30) return `${(durationInDays / 30).toFixed(0)} mois`;
  if (durationInDays >= 1)
    return `${durationInDays} jour${durationInDays > 1 && 's'}`;
  return 'Moins de 1 jour';
};
