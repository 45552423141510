import { Separator, Button } from '@chakra-ui/react';
import { Drawer, SortSelect } from '@/components';
import React, { useContext, useEffect, useState } from 'react';
import {
  MetaAd,
  MetaQueryParams,
  MetaSnapshot,
  PricingPlanCode,
} from '@/types';
import { api } from '@/lib';
import { AdDetail, AdList, FilterList } from './local-components';
import withAuthenticationRequired from '@/hoc/with-authentication-required';
import withSubscriptionRequired from '@/hoc/with-subscription-required';
import { DashboardInfos } from '@/pages/dashboard/local-components';
import { StorageContext } from '@/providers';

interface DashboardProps {
  subscription?: PricingPlanCode | null;
}

const Dashboard: React.FC<DashboardProps> = ({ subscription }) => {
  const { getStorage, setStorage } = useContext(StorageContext);
  const [isSearching, setIsSearching] = useState(false);
  const [ads, setAds] = useState<MetaAd[] | null>(null); // allow a state difference between null (no search start) and empty array (searched but no result)
  const [displayAds, setDisplayAds] = useState<MetaAd[] | null>(null);
  const [countAdsToDiplay, setCountAdsToDisplay] = useState<number>(50);
  const [snapshots, setSnapshots] = useState<MetaSnapshot[]>([]);
  const [selectedAd, setSelectedAd] = useState<MetaAd>();
  const [sort, setSort] = useState<string>('views.desc');
  const [searchesMade, setSearchesMade] = useState<number>(
    parseInt(getStorage('searchesMade') || '0'),
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleOpenAdDetail = (ad: MetaAd) => {
    setIsDrawerOpen(true);
    setSelectedAd(ad);
  };

  /**
   * Each time a search is made, reset count ads to display, sort and set default ads, slice to get minimal display ads then load snapshots
   */
  const search = (filters: MetaQueryParams) => {
    setIsSearching(true);
    setCountAdsToDisplay(50);
    setSnapshots([]);
    // Load global meta data
    api.meta
      .getMetaData(filters)
      .then(async (adsValue) => {
        handleSort(sort, adsValue);
        handleFreeSearches();
      })
      .catch(() => setIsSearching(false));
  };

  /**
   * For an account without any subscription, allow 3 searches
   */
  const handleFreeSearches = () => {
    if (!subscription) {
      const incrementedSearches = searchesMade + 1;
      setStorage('searchesMade', incrementedSearches.toString());
      setSearchesMade(incrementedSearches);
      if (incrementedSearches > 3) {
        window.location.reload();
      }
    }
  };

  const handleSort = (sortValue: string, adsValue?: MetaAd[]) => {
    setSort(sortValue);
    setAds((prev) => {
      const valueToUpdate = adsValue || (prev ? [...prev] : []);
      if (valueToUpdate) {
        return valueToUpdate.sort((a: MetaAd, b: MetaAd) => {
          switch (sortValue) {
            case 'views.desc':
              return b.eu_total_reach - a.eu_total_reach;
            case 'date.asc':
              return (
                new Date(a.ad_creation_time).getTime() -
                new Date(b.ad_creation_time).getTime()
              );
            case 'date.desc':
              return (
                new Date(b.ad_creation_time).getTime() -
                new Date(a.ad_creation_time).getTime()
              );
            default:
              return 0;
          }
        });
      }
      return null;
    });
  };

  /**
   * Each time ads are refreshed or count is increased, update display ads
   */
  useEffect(() => {
    if (ads) {
      setDisplayAds(ads.slice(0, countAdsToDiplay));
    } else {
      setIsSearching(false);
    }
  }, [countAdsToDiplay, ads]);

  useEffect(() => {
    /**
     * When display ads are finally updated, we can setIsSearching at false to stop loader
     */
    setIsSearching(false);
    /**
     * Each time display ads are changed, check if they have a snapshot (succeeded or failed), if they don't, request it
     */
    displayAds?.forEach((ad) => {
      if (!snapshots.find((snapshot) => snapshot.id === ad.id)) {
        api.meta.getMetaSnapshot(ad.id, ad.ad_snapshot_url).then((snapshot) => {
          setSnapshots((prev) => [...prev, snapshot]);
        });
      }
    });
  }, [displayAds]);

  return (
    <div className="flex flex-col gap-8">
      <DashboardInfos subscription={subscription} searchesMade={searchesMade} />
      <FilterList onSearch={search} />
      <div className="flex items-center gap-8">
        <span className="whitespace-nowrap">Vos résultats de recherche</span>
        <Separator />
        <SortSelect onSort={(sort) => handleSort(sort)} value={sort} />
      </div>
      <AdList
        isLoading={isSearching}
        ads={displayAds}
        snapshots={snapshots}
        onAdClick={handleOpenAdDetail}
      />
      {displayAds &&
        displayAds.length > 0 &&
        ads &&
        countAdsToDiplay < ads.length && (
          <Button onClick={() => setCountAdsToDisplay((prev) => prev + 20)}>
            Charger +
          </Button>
        )}
      <Drawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        {selectedAd && (
          <AdDetail
            ad={selectedAd}
            snapshot={snapshots.find(
              (snapshot) => snapshot.id === selectedAd?.id,
            )}
          />
        )}
      </Drawer>
    </div>
  );
};

export const ProtectedDashboard: React.FC = withAuthenticationRequired(
  withSubscriptionRequired(Dashboard),
) as unknown as React.FC;
