import { Box, Button, Card, HStack, Spacer, Text } from '@chakra-ui/react';
import React from 'react';
import { Carousel } from './carousel';
import { SnapshotVideo } from '@/types';
interface AdDisplayProps {
  description?: string | undefined;
  imageUrls?: string[] | undefined;
  videoUrl?: SnapshotVideo | undefined;
  websiteUrl?: string | undefined;
  adUrl?: string;
}

export const AdDisplay: React.FC<AdDisplayProps> = ({
  description,
  imageUrls,
  videoUrl,
  websiteUrl,
  adUrl,
}) => {
  const formatUrl = (url?: string) => {
    if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
      return `https://${url}`;
    }
    return url;
  };

  return (
    <Card.Root maxW="md" overflow="hidden">
      <Box overflow="hidden" position="relative">
        <Carousel imageUrls={imageUrls} videoUrl={videoUrl} />
      </Box>
      <Card.Body gap="2">
        <HStack gap="2">
          {adUrl && (
            <Button
              variant="solid"
              onClick={() => window.open(`${formatUrl(adUrl)}`, '_blank')}
            >
              Voir l&apos;AD
            </Button>
          )}
          <Spacer />
          {websiteUrl && (
            <Button
              variant="outline"
              onClick={() =>
                window.open(`${formatUrl(websiteUrl?.toString())}`, '_blank')
              }
            >
              Voir le site web
            </Button>
          )}
        </HStack>
        {description && (
          <>
            <Text marginTop={5}>Description</Text>
            <Card.Description>{description}</Card.Description>
          </>
        )}
      </Card.Body>
    </Card.Root>
  );
};
