import { Stack, Text, VStack } from '@chakra-ui/react';
import { Button } from '@/components/ui/button';
import { DateRange, DayPicker, Matcher } from 'react-day-picker';
import 'react-day-picker/style.css';
import {
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
  PopoverCloseTrigger,
} from '@/components/ui/popover';
import { useState } from 'react';
import styles from '../components/date-picker.module.css';

interface DatePickerProps {
  value?: string;
  onChange: (selectedValue?: string, selectedValue2?: string) => void;
  disabled?: Matcher;
  label: string;
}
export const DatePicker: React.FC<DatePickerProps> = ({
  value,
  onChange,
  disabled,
  label,
}) => {
  const [selected, setSelected] = useState<string | undefined>(value);
  const [dateRange, setDateRange] = useState<DateRange | undefined>();

  const updateDateRange = (newdateRange?: DateRange) => {
    const fromDate = newdateRange?.from
      ? newdateRange.from.toISOString().split('T')[0]
      : '';
    const toDate = newdateRange?.to
      ? newdateRange.to.toISOString().split('T')[0]
      : '';

    setDateRange(newdateRange);
    setSelected(displayDateRange('fr-fr', newdateRange));
    onChange(fromDate, toDate);
  };

  const displayDate = (lang: string, date?: Date) =>
    date
      ? date.toLocaleDateString(lang, {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        })
      : '';

  const displayDateRange = (lang: string, dateRange?: DateRange) =>
    dateRange
      ? `${displayDate(lang, dateRange.from)} au ${displayDate(lang, dateRange.to)}`
      : '';

  const today = new Date();

  const setPredefinedDateRange = (from: number, to: number) => {
    const fromDay = new Date(today);
    fromDay.setDate(fromDay.getDate() - from);
    const toDay = new Date(today);
    toDay.setDate(toDay.getDate() - to);
    updateDateRange({ from: fromDay, to: toDay });
  };

  const buttons = [
    { label: 'Aujourdhui', onClick: () => setPredefinedDateRange(0, 0) },
    { label: '7 derniers jours', onClick: () => setPredefinedDateRange(7, 0) },
    {
      label: '14 derniers jours',
      onClick: () => setPredefinedDateRange(14, 0),
    },
    {
      label: '30 derniers jours',
      onClick: () => setPredefinedDateRange(30, 0),
    },
    {
      label: 'Cette semaine',
      onClick: () => setPredefinedDateRange(today.getDay(), 0),
    },
    {
      label: 'Dernière semaine',
      onClick: () =>
        setPredefinedDateRange(today.getDay() + 7, +today.getDay() + 1),
    },
  ];

  // see positioning props at https://ark-ui.com/react/docs/components/popover
  return (
    <PopoverRoot
      positioning={{
        placement: 'bottom-start',
        flip: false,
        slide: false,
      }}
    >
      <PopoverTrigger asChild>
        <Button size="md" variant="outline">
          <Text>{label}</Text>
          <div
            className={`h-4 w-4 ${dateRange && 'rounded-full bg-black text-xs text-white'}`}
          >
            {dateRange && '•'}
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent w={'auto'}>
        <PopoverArrow />
        <PopoverBody>
          <Stack direction={'row'} gap={5}>
            <DayPicker
              mode="range"
              timeZone={'UTC'}
              numberOfMonths={2}
              showOutsideDays={true}
              selected={dateRange}
              onSelect={updateDateRange}
              disabled={disabled}
              footer={
                dateRange ? `Sélectionné: ${selected}` : 'Choisissez une date.'
              }
              className={styles.datePicker}
            />
            <VStack justifyContent={'center'} alignItems={'center'}>
              {buttons.map((button, index) => (
                <Button key={index} onClick={button.onClick} w="full">
                  {button.label}
                </Button>
              ))}
            </VStack>
          </Stack>
        </PopoverBody>
        <PopoverCloseTrigger />
      </PopoverContent>
    </PopoverRoot>
  );
};
