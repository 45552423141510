import React, { Suspense, useEffect, useState } from 'react';
import PricingTable from './local-components/pricing-table';
import SubscriptionSucceededMessage from './local-components/subscription-succeeded-message';
import SubscriptionManagement from '@/pages/plans/local-components/subscription-management';
import { PricingPlanCode } from '@/types/pricing';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { api } from '@/lib';
import { toast, ToastMessages } from '@/lib/toast';

export const Plans: React.FC = () => {
  // get user current subscription from stripe api
  const { user, isLoading } = useAuth0();
  const [subscription, setSubscription] = useState<PricingPlanCode | null>(
    null,
  );
  useEffect(() => {
    if (!isLoading && user?.app_metadata?.stripe_customer_id) {
      api.payment
        .getUserSubscription(user.app_metadata.stripe_customer_id)
        .then((res) => setSubscription(res.currentPlan));
    }
  }, [user, isLoading]);

  // if purchased has just been made
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const success = queryParams.get('success') as PricingPlanCode; // after a subscription, redirection is on /plans?success=true

  const handleCustomerPortalRedirection = () => {
    return api.payment
      .generatePortalUrl(user?.app_metadata?.stripe_customer_id)
      .then((url) => {
        window.location.href = url;
      })
      .catch(() => {
        toast(ToastMessages.CONTACT_SUPPORT);
      });
  };
  return (
    <div className={'pt-20'}>
      <Suspense>
        {subscription &&
          (success ? (
            <SubscriptionSucceededMessage
              pricingPlanCode={subscription}
              onCustomerPortalRedirection={handleCustomerPortalRedirection}
            />
          ) : (
            <SubscriptionManagement
              pricingPlanCode={subscription}
              onCustomerPortalRedirection={handleCustomerPortalRedirection}
            />
          ))}
      </Suspense>
      <PricingTable currentSubscription={subscription} />
    </div>
  );
};
