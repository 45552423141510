import React, { createContext, ReactNode } from 'react';

interface StorageContextInterface {
  getStorage: (key: string | undefined) => string | null;
  setStorage: (key: string, value: string) => void;
}
/* eslint-disable @typescript-eslint/no-unused-vars */
export const StorageContext = createContext<StorageContextInterface>({
  getStorage: (key: string | undefined) => null,
  setStorage: (key: string, value: string) => {},
});
/* eslint-enable @typescript-eslint/no-unused-vars */

export const StorageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const getStorage = (key: string | undefined) => {
    return localStorage.getItem(key || '');
  };
  const setStorage = (key: string, value: string) => {
    return localStorage.setItem(key, value);
  };

  return (
    <StorageContext.Provider value={{ getStorage, setStorage }}>
      {children}
    </StorageContext.Provider>
  );
};
