import React from 'react';

export const Cgu: React.FC = () => {
  return (
    <div className={'m-8'}>
      <h4 className={'text-xl'}>1. Présentation du service</h4>
      Leadspy est une plateforme SaaS qui propose [décrire brièvement votre
      service, par exemple : des outils de gestion et d’analyse de leads pour
      les entreprises].
      <br />
      <br />
      <h4 className={'text-xl'}>2. Accès au service</h4>
      L’accès à Leadspy nécessite :
      <br />- La création d’un compte utilisateur ;
      <br />- Un abonnement actif ;
      <br />- Une connexion internet.
      <br />
      <br />
      Vous êtes responsable de la confidentialité de vos identifiants de
      connexion.
      <br />
      <br />
      <h4 className={'text-xl'}>3. Abonnements et paiements</h4>
      - Nos services sont disponibles sous forme d’abonnements payants mensuels
      ou annuels. Les tarifs sont indiqués sur notre site.
      <br />- Le paiement s’effectue par carte bancaire via une plateforme
      sécurisée.
      <br />- L’abonnement est reconduit automatiquement, sauf résiliation avant
      la date d’échéance.
      <br />- Aucun remboursement ne sera effectué en cas d’annulation en cours
      de période d’abonnement.
      <br />
      <br />
      <h4 className={'text-xl'}>4. Utilisation acceptable</h4>
      Vous acceptez de ne pas utiliser Leadspy :
      <br />- À des fins illégales ou frauduleuses ;
      <br />- Pour perturber le fonctionnement de la plateforme ;
      <br />- Pour accéder sans autorisation aux données d’autres utilisateurs.
      <br />
      <br />
      En cas de non-respect, nous nous réservons le droit de suspendre ou de
      résilier votre compte.
      <br />
      <br />
      <h4 className={'text-xl'}>5. Propriété intellectuelle</h4>
      Tout le contenu de Leadspy (textes, logos, code, etc.) est protégé par les
      lois sur la propriété intellectuelle. Vous ne pouvez pas copier, modifier
      ou distribuer ce contenu sans notre autorisation écrite.
      <br />
      <br />
      <h4 className={'text-xl'}>6. Responsabilité</h4>
      Leadspy s’efforce de maintenir le service accessible et sécurisé.
      Toutefois :
      <br />- Nous ne garantissons pas l’absence d’interruptions ou d’erreurs ;
      <br />- Nous déclinons toute responsabilité en cas de pertes de données ou
      de dommages indirects liés à l’utilisation de la plateforme.
      <br />
      <br />
      <h4 className={'text-xl'}>7. Résiliation</h4>
      Vous pouvez résilier votre abonnement à tout moment depuis votre espace
      client. Votre accès restera actif jusqu’à la fin de la période en cours.
      <br />
      <br />
      Nous nous réservons le droit de résilier votre accès en cas de non-respect
      des CGU ou de comportement nuisible.
      <br />
      <br />
      <h4 className={'text-xl'}>8. Données personnelles</h4>
      La gestion de vos données personnelles est détaillée dans notre{' '}
      <strong>Politique de Confidentialité</strong>. En utilisant Leadspy, vous
      acceptez notre traitement des données conformément au RGPD.
      <br />
      <br />
      <h4 className={'text-xl'}>9. Modification des CGU</h4>
      Nous nous réservons le droit de modifier ces CGU à tout moment. Toute
      modification sera communiquée sur notre site et applicable immédiatement.
      <br />
      <br />
      <h4 className={'text-xl'}>10. Droit applicable</h4>
      Ces CGU sont soumises à la législation française. En cas de litige, et à
      défaut d’accord amiable, les tribunaux compétents seront ceux de Rennes.
      <br />
      <br />
      Pour toute question ou réclamation, vous pouvez nous contacter à :
      <br />
      <strong>Email</strong> : contact@leadspy.app
      <br />
      <strong>Adresse</strong> : Rennes
    </div>
  );
};
