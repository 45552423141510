export enum PricingPlanCode {
  STARTER = 'STARTER',
  PREMIUM = 'PREMIUM',
  BUSINESS = 'BUSINESS',
  FREE = 'FREE',
}
export interface Subscription {
  isActive: boolean;
  currentPlan: PricingPlanCode | null;
}
