import { createListCollection, HStack, ListCollection } from '@chakra-ui/react';
import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from '@/components/ui';
import { MdOutlineSort } from 'react-icons/md';

interface SortProps {
  value: string;
  onSort: (sort: string) => void;
  canSort?: boolean;
}

export const SortSelect: React.FC<SortProps> = ({ onSort, canSort, value }) => {
  const sorts: ListCollection<{ label: string; value: string }> =
    createListCollection({
      items: [
        { label: 'Les plus vues', value: 'views.desc' },
        { label: 'Les plus récentes', value: 'date.desc' },
        { label: 'Les plus anciennes', value: 'date.asc' },
      ],
    });

  return (
    <SelectRoot
      collection={sorts}
      className="max-w-64"
      defaultValue={[value]}
      value={[value]}
      onValueChange={(e: { value: string[] }) => onSort(e.value[0])}
      disabled={canSort}
    >
      <SelectTrigger>
        <HStack>
          <MdOutlineSort />
          <SelectValueText
            className={'cursor-pointer'}
            placeholder="Trier par"
          />
        </HStack>
      </SelectTrigger>
      <SelectContent>
        {sorts?.items?.map((sort) => (
          <SelectItem className={'cursor-pointer'} item={sort} key={sort.value}>
            {sort.label}
          </SelectItem>
        ))}
      </SelectContent>
    </SelectRoot>
  );
};
