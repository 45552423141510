import { Verified } from '@/components/icons/verified';
import { Instagram } from '@/components/icons/instagram';

export const ICONS = {
  verified: Verified,
  instagram: Instagram,
};
export interface IconProps {
  name: keyof typeof ICONS;
  size: 'small' | 'medium' | 'large';
}

export const Icon: React.FC<IconProps> = ({ name, size = 'small' }) => {
  const SelectedIcon = ICONS[name];
  const mappedSizes = {
    small: 24,
    medium: 36,
    large: 64,
  };
  return (
    <div
      style={{
        width: size,
        height: size,
        display: 'inline-block',
        lineHeight: 0,
      }}
    >
      <SelectedIcon width={mappedSizes[size]} height={mappedSizes[size]} />
    </div>
  );
};
