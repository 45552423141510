import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, ButtonProps } from '@/components/ui';

export const LogoutButton = (props: ButtonProps) => {
  const { logout } = useAuth0();

  return (
    <Button
      variant="outline"
      onClick={() =>
        logout({ logoutParams: { returnTo: window.location.origin } })
      }
      {...props}
    >
      Se déconnecter
    </Button>
  );
};
