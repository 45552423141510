interface MinifiedNumberProps {
  number: number;
}

export const MinifiedNumber: React.FC<MinifiedNumberProps> = ({ number }) => {
  const formatNumber = (num: number) => {
    if (num >= 1000000)
      return (num / 1000000).toFixed(1).replace('.0', '') + 'M';
    if (num >= 1000) return (num / 1000).toFixed(1).replace('.0', '') + 'k';
    return String(num.toFixed(1)).replace('.0', '');
  };
  return <>{formatNumber(number)}</>;
};
