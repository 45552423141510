import React from 'react';

export const Policy: React.FC = () => {
  return (
    <div className={'m-8'}>
      <h4 className={'text-xl'}>1. Les Services</h4>
      Leadspy (&#34;nous&#34;) exploite plusieurs sites web, y compris
      leadspy.app et ses sous-domaines associés. Nous nous engageons à respecter
      votre vie privée concernant toutes les informations que nous pourrions
      collecter lors de l’utilisation de nos sites web.
      <br />
      <br />
      <h4 className={'text-xl'}>2. Visiteurs du site web</h4>
      Comme la plupart des opérateurs de sites web, Leadspy collecte des
      informations non identifiantes personnellement, telles que le type de
      navigateur, la langue préférée, le site de provenance, ainsi que la date
      et l’heure de chaque requête des visiteurs. Ces informations permettent à
      Leadspy de mieux comprendre comment les visiteurs utilisent le site et
      d’améliorer le contenu proposé.
      <br />
      <br />
      Nous pouvons parfois publier des données globales, comme des rapports sur
      les tendances d’utilisation du site. Par ailleurs, Leadspy collecte des
      adresses IP des utilisateurs connectés, mais celles-ci ne sont divulguées
      que dans les cas prévus ci-dessous.
      <br />
      <br />
      <h4 className={'text-xl'}>3. Collecte des informations personnelles</h4>
      Certains visiteurs choisissent d’interagir avec Leadspy de manière à
      nécessiter la collecte d’informations personnelles, comme le nom, le
      téléphone ou l’email. Les utilisateurs effectuant des transactions sur
      Leadspy – par exemple en souscrivant un abonnement payant – doivent
      fournir des informations supplémentaires, notamment financières, pour
      traiter ces transactions.
      <br />
      <br />
      Leadspy collecte uniquement les informations nécessaires pour répondre aux
      besoins de l’interaction. Les visiteurs peuvent refuser de fournir des
      informations personnelles, mais cela peut limiter leur accès à certaines
      fonctionnalités.
      <br />
      <br />
      <h4 className={'text-xl'}>4. Statistiques agrégées</h4>
      Leadspy peut collecter des statistiques sur le comportement des visiteurs,
      mais ne divulgue pas d’informations personnelles, sauf dans les cas prévus
      ci-dessous.
      <br />
      <br />
      <h4 className={'text-xl'}>5. Protection des informations personnelles</h4>
      Leadspy partage des informations personnelles uniquement avec ses
      employés, sous-traitants ou organisations affiliées qui :
      <br />
      (i) ont besoin de ces informations pour les traiter pour le compte de
      Leadspy ou pour fournir les services liés ;
      <br />
      (ii) se sont engagés à ne pas les divulguer à des tiers.
      <br />
      <br />
      Ces informations peuvent être transférées hors de votre pays. En utilisant
      nos services, vous acceptez ce transfert. Nous ne vendons ni ne louons vos
      informations personnelles. Cependant, nous pouvons les divulguer en cas de
      demande légale, ou si cela est nécessaire pour protéger les droits de
      Leadspy, de ses utilisateurs ou du public.
      <br />
      <br />
      Les utilisateurs enregistrés peuvent recevoir des emails d’information.
      Nous nous réservons le droit de publier vos requêtes (par exemple, via le
      support) pour y répondre ou aider d’autres utilisateurs.
      <br />
      <br />
      <h4 className={'text-xl'}>6. Cookies</h4>
      Un cookie est un fichier stocké sur votre ordinateur, permettant de suivre
      votre utilisation du site. Leadspy utilise des cookies pour identifier et
      suivre les visiteurs et leurs préférences. Vous pouvez configurer votre
      navigateur pour refuser les cookies, mais certaines fonctionnalités du
      site pourraient ne pas fonctionner correctement.
      <br />
      <br />
      <h4 className={'text-xl'}>7. Transferts d’activité</h4>
      En cas d’acquisition de Leadspy ou de ses actifs, les informations
      utilisateur peuvent être transférées à un tiers. Vous acceptez que ces
      transferts puissent se produire et que le nouvel acquéreur continue
      d’utiliser vos données comme décrit ici.
      <br />
      <br />
      <h4 className={'text-xl'}>8. Contenu tiers</h4>
      Le contenu tiers sur nos sites peut être fourni par des partenaires, qui
      utilisent des cookies pour collecter des informations sur vos
      interactions. Cette Politique de Confidentialité couvre l’utilisation des
      cookies par Leadspy, mais pas celle des partenaires tiers.
      <br />
      <br />
      <h4 className={'text-xl'}>
        9. Modifications de la Politique de Confidentialité
      </h4>
      Bien que la plupart des modifications soient mineures, Leadspy peut
      modifier sa Politique de Confidentialité à tout moment. Nous encourageons
      les visiteurs à consulter cette page régulièrement. En continuant à
      utiliser le site après une modification, vous acceptez ces changements.
      <br />
      <br />
      Cette politique est publiée sous licence Creative Commons Sharealike,
      initialement fournie par www.automattic.com.
    </div>
  );
};
