import { Image } from '@chakra-ui/react';

interface LogoProps {
  type: 'short' | 'full';
  ratio?: number;
}
export const Logo: React.FC<LogoProps> = ({ type = 'full', ratio = 1 }) => {
  const getLogoSrc = () => {
    return type === 'full' ? '/images/logo-full.svg' : '/images/logo-short.svg';
  };
  const getLogoSizing = (): { width: number; height: number } => {
    return type === 'full'
      ? { width: 500 * ratio, height: 174 * ratio }
      : { width: 100 * ratio, height: 165 * ratio };
  };
  return (
    <Image
      width={getLogoSizing().width}
      height={getLogoSizing().height}
      src={getLogoSrc()}
      alt={'leadspy-logo'}
    />
  );
};
