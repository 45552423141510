import Stripe from 'stripe';
import { PricingPlanCode, Subscription } from '@/types';

interface StripeSubscription {
  status: string;
  plan: { product: string };
}
export class Payment {
  private paymentApi;
  constructor() {
    this.paymentApi = new Stripe(import.meta.env.VITE_STRIPE_SECRET_KEY);
  }
  getUserSubscription = (customerId: string): Promise<Subscription> => {
    const defaultSubscription: Subscription = {
      isActive: false,
      currentPlan: null,
    };
    return this.paymentApi.subscriptions
      .list({ customer: customerId })
      .then(async (subscriptions) => {
        const filteredSub: StripeSubscription | null = (subscriptions.data.find(
          (sub) => ['trialing', 'active'].includes(sub.status),
        ) || null) as unknown as StripeSubscription | null; // because stripe types does not recognize "plan" key in stripe sub resource
        if (filteredSub) {
          return this.paymentApi.products
            .retrieve(filteredSub.plan.product)
            .then((product) => {
              return {
                isActive: true,
                currentPlan: Object.keys(PricingPlanCode).find((code) =>
                  product.name.toLowerCase().includes(code.toLowerCase()),
                ) as PricingPlanCode | null,
              };
            });
        }
        return defaultSubscription;
      })
      .catch(() => defaultSubscription);
  };
  generatePricingLink = (
    priceId: string,
    customerId: string,
  ): Promise<string> => {
    return this.paymentApi.checkout.sessions
      .create({
        success_url: window.location.origin + '/plans?success=true',
        line_items: [{ price: priceId, quantity: 1 }],
        subscription_data: {
          trial_period_days: 7,
        },
        mode: 'subscription',
        customer: customerId,
      })
      .then((session) => {
        if (session?.url) {
          return session.url;
        }
        throw new Error('session url not set');
      });
  };
  generatePortalUrl = (customerId: string): Promise<string> => {
    return this.paymentApi.billingPortal.sessions
      .create({
        customer: customerId,
        return_url: window.location.origin + '/plans',
      })
      .then((portal) => portal.url);
  };
}
