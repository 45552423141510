import React from 'react';

export const Legal: React.FC = () => {
  return (
    <p className={'m-8'}>
      <b>
        LEADSPY Société par Actions Simplifiée (Société par actions simplifiée)
      </b>
      <br />
      SIREN : FR RCS Rennes
      <br />
      Adresse de l’entreprise : Rennes
      <br />
      Numéro TVA :
      <br />
      Dirigeant : Arthur Corbihan
      <br />
      Hébergé par : CloudFlare, Inc., 101 Townsend St, San Francisco, CA 94107
      USA
      <br />
      <br />
      <b>Adresse</b>
      <br />
      Rennes
      <br />
      <br />
      <b>Contact</b>
      <br />
      contact@leadspy.app
      <br />
      <br />
      <b>Abus</b>
      <br />
      Pour signaler un contenu litigieux ou si vous êtes victime d’une
      utilisation frauduleuse d’un service LEADSPY, veuillez nous contacter par
      e-mail à contact@leadspy.app.
      <br />
      <br />
      <b>Propriété intellectuelle</b>
      <br />
      Ce site web et tout son contenu (y compris les données, informations,
      photos, logos et marques) sont la propriété exclusive de LEADSPY SAS ou de
      ses partenaires. Toute reproduction, représentation, traduction,
      adaptation ou citation, en tout ou en partie, quel que soit le procédé ou
      le support, est strictement interdite sauf dans les cas prévus par la loi
      ou expressément autorisée par leur propriétaire. Les photos ne sont pas
      contractuelles.
      <br />
      <br />
      <b>Collecte des données personnelles</b>
      <br />
      Vous pouvez visiter notre site internet sans avoir à divulguer votre
      identité ou à fournir des informations personnelles vous concernant.
      Cependant, nous pourrions parfois demander des informations pour traiter
      une commande, identifier une demande de support, établir des
      correspondances, fournir un abonnement, ou postuler à un poste.
      <br />
      <br />
      <b>Traitement des données personnelles</b>
      <br />
      Conformément au RGPD, vous disposez des droits suivants concernant vos
      données personnelles
      <br />- Droit d’accès : Obtenir une copie de vos données.
      <br />- Droit de rectification : Corriger vos données si elles sont
      inexactes.
      <br />- Droit à l’effacement (ou droit à l’oubli) : Supprimer vos données,
      sauf si leur conservation est requise par des obligations légales ou
      contractuelles.
      <br />- Droit d’opposition : Refuser certains traitements de vos données.
      <br />- Droit à la limitation : Restreindre temporairement l’utilisation
      de vos données.
      <br />
      Vous pouvez également demander l’anonymisation de vos données personnelles
      collectées sur notre site web.
      <br />
      Pour exercer vos droits, veuillez nous contacter à l’adresse suivante :
      contact@leadspy.app. Nous pourrons vous demander une pièce justificative
      pour confirmer votre identité.
      <br />
      Votre demande sera traitée dans un délai maximum de 30 jours, sauf si des
      circonstances particulières nécessitent une prolongation, conformément à
      la législation en vigueur.
      <br />
      <br />
      <b>
        Avis et procédure pour déposer des réclamations pour violation de droits
        d’auteur
      </b>
      <br />
      Si vous estimez que votre œuvre a été copiée d’une manière qui constitue
      une violation de droits d’auteur, veuillez fournir à notre agent des
      droits d’auteur les informations écrites spécifiées ci-dessous. Veuillez
      noter que cette procédure est exclusivement destinée à informer LEADSPY
      que votre matériel protégé par des droits d’auteur a été enfreint.
      <br />- Une signature électronique ou physique de la personne autorisée à
      agir au nom du propriétaire des droits d’auteur ;
      <br />- Une description de l’œuvre protégée par des droits d’auteur que
      vous prétendez avoir été enfreinte ;
      <br />- Une description de l’endroit où se trouve le matériel que vous
      affirmez être en violation sur le site ;
      <br />- Votre adresse, numéro de téléphone et adresse e-mail ;
      <br />- Une déclaration de votre part indiquant que vous avez une croyance
      de bonne foi que l’utilisation contestée n’est pas autorisée par le
      propriétaire des droits d’auteur, son agent ou la loi ;
      <br />- Une déclaration de votre part, faite sous peine de parjure, que
      les informations ci-dessus dans votre avis sont exactes et que vous êtes
      le propriétaire des droits d’auteur ou autorisé à agir au nom du
      propriétaire des droits d’auteur.
      <br />
      <br />
      <b>
        L’agent des droits d’auteur de LeadSpy pour les notifications de
        réclamations de violation des droits d’auteur peut être contacté comme
        suit :
      </b>
      <br />
      E-mail : contact@leadspy.app
      <br />
      <br />
      <b>Mise à jour</b>
      <br />
      Si nous devons mettre à jour, modifier ou apporter des changements à ce
      document, ces changements seront affichés de manière évidente ici.
    </p>
  );
};
