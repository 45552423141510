import React, { Suspense } from 'react';
import { ROUTES } from '@/types';
import { LoginButton, SignInButton } from '@/components/auth';
import { LinkButton } from '@/components/ui';
import { useLocation } from 'react-router-dom';

function UnauthorizedMessage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const unauthorizedCode = queryParams.get('code') as string;

  if (unauthorizedCode === 'unauthenticated') {
    return (
      <>
        <div className="container flex flex-col items-center justify-center gap-32 px-10 md:flex-row lg:flex-row xl:flex-row">
          <div className="container flex flex-col">
            <span
              className={
                'mb-8 font-semibold sm:text-left md:text-balance lg:text-center xl:text-center'
              }
            >
              <p>
                Connectez-vous ou inscrivez-vous pour profiter de nos services.
              </p>
            </span>
            <LoginButton className={'mb-4'} />
            <SignInButton />
          </div>
          <div>
            <video
              src="/VSL_Lead_spy_V1.mp4"
              playsInline
              controls
              autoPlay
              className="h-full w-full rounded-lg object-cover shadow-xl"
            />
          </div>
        </div>
      </>
    );
  } else if (unauthorizedCode === 'unsubscribed') {
    return (
      <>
        <span className={'mb-2 text-sm'}>
          Vous avez atteint votre nombre maximum de recherches gratuites
        </span>
        <span className={'mb-4 font-semibold'}>
          Veuillez sélectionner un abonnement
        </span>
        <LinkButton variant="outline" href={ROUTES.PLANS}>
          Voir les abonnements
        </LinkButton>
      </>
    );
  }

  return (
    <>
      <span className={'mb-8'}>
        Vous n&#39;avez pas l&#39;autorisation d&#39;accéder à cette page
      </span>
      <LinkButton variant="outline" href={ROUTES.PLANS}>
        Voir les abonnements
      </LinkButton>
    </>
  );
}

export const Unauthorized: React.FC = () => {
  return (
    <Suspense>
      <div className={'my-16 flex flex-col items-center'}>
        <UnauthorizedMessage />
      </div>
    </Suspense>
  );
};
