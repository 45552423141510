import { Link, LinkProps } from '@chakra-ui/react';
import { Logo } from '@/components/logo';
import { ROUTES } from '@/types';

// used https://templateskart.com/components/footers
export const Footer = () => {
  return (
    <div className="border-t">
      <div className="container mx-auto px-4 py-8 md:px-6">
        <div className="flex flex-col justify-around gap-8 sm:flex-row">
          <div>
            <Logo type={'full'} ratio={0.4} />
            <span className="mt-2">Spy to win</span>
          </div>
          <div className="flex flex-col gap-4 md:flex-row">
            <div>
              <h3 className="font-bold">Notre entreprise</h3>
              <ul>
                <li>
                  <CustomLink href={ROUTES.ABOUT.LEGAL}>
                    Mentions légales
                  </CustomLink>
                </li>
                <li>
                  <CustomLink href={ROUTES.ABOUT.CGU}>Nos CGU</CustomLink>
                </li>
                <li>
                  <CustomLink href={ROUTES.ABOUT.POLICY}>
                    Politique de confidentialité
                  </CustomLink>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold">Ressources</h3>
              <ul>
                <li>
                  <CustomLink href={'mailto:contact@leadspy.app'}>
                    Nous contacter
                  </CustomLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomLink = ({ children, ...props }: LinkProps) => {
  return (
    <Link
      href="#"
      fontSize="sm"
      _hover={{ textDecoration: 'underline' }}
      {...props}
    >
      {children}
    </Link>
  );
};

export default Footer;
