import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, ButtonProps } from '@/components/ui';

export const SignInButton = (props: ButtonProps) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      onClick={() =>
        loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } })
      }
      {...props}
    >
      S&#39;inscrire
    </Button>
  );
};
