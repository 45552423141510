import React, { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import { PricingPlanCode, ROUTES } from '@/types';
import { Spinner } from '@chakra-ui/react';
import { api } from '@/lib';
import { StorageContext } from '@/providers';

interface WithSubscriptionProps {
  subscription?: PricingPlanCode | null;
}

const withSubscriptionRequired = (
  Component: React.ComponentType<WithSubscriptionProps>,
): React.FC<WithSubscriptionProps> => {
  return function checkSubscription() {
    const { getStorage } = useContext(StorageContext);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPlan, setCurrentPlan] = useState<PricingPlanCode | null>(
      null,
    );

    const freeSearchesMade = getStorage('searchesMade');
    const exceededFreeSearches =
      freeSearchesMade && parseInt(freeSearchesMade) > 3;

    const { user } = useAuth0();
    useEffect(() => {
      const retrieveUserSubscription = async () => {
        await api.payment
          .getUserSubscription(user?.app_metadata?.stripe_customer_id || '')
          .then((response) => {
            setCurrentPlan(response.currentPlan);
          })
          .catch(() => setCurrentPlan(null))
          .finally(() => setIsLoading(false));
      };
      retrieveUserSubscription();
    }, []);
    if (isLoading) {
      return (
        <div className="flex grow items-center justify-center">
          <Spinner size={'xl'} borderWidth={'4px'} className={'m-8'} />
        </div>
      );
    }
    if (!currentPlan && exceededFreeSearches) {
      return <Navigate to={ROUTES.UNAUTHORIZED + '?code=unsubscribed'} />;
    }

    return <Component subscription={currentPlan} />;
  };
};

export default withSubscriptionRequired;
