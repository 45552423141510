import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '@/types';
import { Spinner } from '@chakra-ui/react';

const withAuthenticationRequired = (
  Component: React.ComponentType,
): React.FC => {
  return function checkLogin() {
    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
      return (
        <div className="flex grow items-center justify-center">
          <Spinner size={'xl'} borderWidth={'4px'} className={'m-8'} />
        </div>
      );
    }

    if (!isAuthenticated) {
      return <Navigate to={ROUTES.UNAUTHORIZED + '?code=unauthenticated'} />;
    }

    return <Component />;
  };
};

export default withAuthenticationRequired;
