import React from 'react';
import { ChakraProvider, defaultSystem } from '@chakra-ui/react';
import {
  ColorModeProvider,
  ColorModeProviderProps,
} from '@/components/ui/color-mode';

export function UiProvider(props: ColorModeProviderProps) {
  return (
    <ChakraProvider value={defaultSystem}>
      <ColorModeProvider {...props} forcedTheme={'light'} />
    </ChakraProvider>
  );
}
