// do a default const componnt and export it

import { SnapshotVideo } from '@/types';
import { IconButton, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export type CarouselProps = {
  imageUrls?: string[];
  videoUrl?: SnapshotVideo;
  isPreview?: boolean;
  className?: string;
};

export const Carousel: React.FC<CarouselProps> = ({
  imageUrls,
  videoUrl,
  isPreview,
  className,
}) => {
  const [slider, setSlider] = React.useState<Slider | null>(null);

  const top = useBreakpointValue({ base: '90%', md: '50%' });
  const side = useBreakpointValue({ base: '30%', md: '10px' });

  const defaultImage =
    'https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif?20151024034921';
  const noImage =
    'https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg';

  const hasVideo = Boolean(videoUrl?.video_sd_url);
  const hasPreviewImage = Boolean(videoUrl?.video_preview_image_url);
  const hasImages = Array.isArray(imageUrls) && imageUrls.length > 0;

  const showPreviewVideo = hasVideo && hasPreviewImage && isPreview;
  const showVideo = hasVideo && (!isPreview || !hasPreviewImage);
  const showImage = !hasVideo && hasImages;
  const showLoading = !hasImages && !videoUrl;
  const showNoImage = imageUrls?.length === 0 && !videoUrl;

  const hasMultipleImagesAndNoVideo = !!(
    imageUrls &&
    imageUrls.length > 1 &&
    !hasVideo &&
    !isPreview
  );

  const settings = {
    dots: !isPreview,
    infinite: hasMultipleImagesAndNoVideo,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <IconButton
        aria-label="left-arrow"
        colorScheme="messenger"
        borderRadius="full"
        position="absolute"
        left={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => slider?.slickPrev()}
        display={hasMultipleImagesAndNoVideo ? 'flex' : 'none'}
      >
        <BiLeftArrowAlt />
      </IconButton>
      <IconButton
        aria-label="right-arrow"
        colorScheme="messenger"
        borderRadius="full"
        position="absolute"
        right={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => slider?.slickNext()}
        display={hasMultipleImagesAndNoVideo ? 'flex' : 'none'}
      >
        <BiRightArrowAlt />
      </IconButton>
      <Slider
        {...settings}
        ref={(slider) => setSlider(slider)}
        className={className}
      >
        {/* If it's a preview mode and there is a preview image */}
        {showPreviewVideo && (
          <div className="group relative flex items-center justify-center overflow-hidden">
            <img
              className="aspect-square h-full w-full object-cover group-hover:hidden"
              src={videoUrl?.video_preview_image_url}
              alt="ad-image"
            />
            <video
              className="hidden aspect-square h-full w-full object-cover group-hover:block"
              src={videoUrl?.video_sd_url}
              autoPlay
              loop
              muted
            />
          </div>
        )}

        {/* If it's a preview mode or if there is no preview image */}
        {showVideo && (
          <div className="relative flex items-center justify-center overflow-hidden">
            <video
              className="aspect-square h-full w-full object-cover"
              src={videoUrl?.video_sd_url}
              autoPlay
              loop
              muted
            />
          </div>
        )}

        {/* If it's not a preview mode */}
        {showImage &&
          imageUrls?.map((url, index) => (
            <div
              className="relative flex items-center justify-center overflow-hidden"
              key={index}
            >
              <img
                className="aspect-square h-full w-full object-cover"
                src={url}
                alt="ad-image"
              />
            </div>
          ))}

        {/* If there is no image (loading or no data) */}
        {(showLoading || showNoImage) && (
          <div className="relative flex items-center justify-center overflow-hidden">
            <img
              className="aspect-square h-full w-full object-cover"
              src={showNoImage ? noImage : defaultImage}
              alt="default-image"
            />
          </div>
        )}
      </Slider>
    </>
  );
};
