import React from 'react';
import { Button, LinkButton } from '@/components/ui';
import { PricingPlanCode, ROUTES } from '@/types';

interface SubscriptionSucceededMessageProps {
  pricingPlanCode: PricingPlanCode;
  onCustomerPortalRedirection: () => void;
}

const SubscriptionSucceededMessage: React.FC<
  SubscriptionSucceededMessageProps
> = ({ pricingPlanCode, onCustomerPortalRedirection }) => {
  return (
    <div className={'mb-8 flex flex-col items-center justify-center'}>
      <h2 className={'text-2xl font-bold'}>Félicitations ! </h2>
      <span className={'my-4'}>
        Votre abonnement LEADSPY à la formule {pricingPlanCode} est confirmée
      </span>
      <Button
        className={'mb-4'}
        variant={'outline'}
        onClick={onCustomerPortalRedirection}
      >
        Gérer mon abonnement
      </Button>
      <LinkButton href={ROUTES.DASHBOARD}>Mon tableau de bord</LinkButton>
    </div>
  );
};

export default SubscriptionSucceededMessage;
