import { HStack, Spacer, Text, useBreakpointValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/react';
import { LinkButton } from '@/components/ui';
import { Logo, MenuBurger } from '@/components';
import { ROUTES } from '@/types';
import { LoginButton, LogoutButton, SignInButton } from '@/components/auth';
import { useAuth0 } from '@auth0/auth0-react';
export const Navbar: React.FC = () => {
  const isVisible = useBreakpointValue({ base: false, xl: true });
  const { user, isLoading } = useAuth0();

  return (
    <div className="shadow">
      <div className="container mx-auto flex items-center px-4 py-2 md:px-6">
        <ChakraLink
          asChild
          _hover={{ textDecoration: 'none' }}
          _focus={{ outline: 'none' }}
        >
          <Link to={ROUTES.DASHBOARD} className={'pt-4'}>
            <Logo type={'full'} ratio={0.3} />
            <Text className={'pb-4'}>Spy To Win</Text>
          </Link>
        </ChakraLink>
        <Spacer />
        <HStack gap={5} display={isVisible ? 'flex' : 'none'}>
          <HStack gap={1}>
            <LinkButton href={ROUTES.PLANS}>Abonnements</LinkButton>
          </HStack>
          <Spacer />
          {!isLoading && (
            <>
              {user ? (
                <>
                  <span>Bienvenue {user?.given_name} !</span>
                  <LogoutButton />
                </>
              ) : (
                <>
                  <LoginButton />
                  <SignInButton />
                </>
              )}
            </>
          )}
        </HStack>
        <MenuBurger user={user} />
      </div>
    </div>
  );
};
