import React from 'react';
import { Button } from '@/components/ui';
import { PricingPlanCode } from '@/types';
import { IoCheckmarkCircle } from 'react-icons/io5';

interface SubscriptionManagementProps {
  pricingPlanCode: PricingPlanCode;
  onCustomerPortalRedirection: () => void;
}

const SubscriptionManagement: React.FC<SubscriptionManagementProps> = ({
  pricingPlanCode,
  onCustomerPortalRedirection,
}) => {
  return (
    <div className={'mb-8 flex flex-col items-center'}>
      <p className={'mb-4 flex flex-row items-center font-semibold'}>
        Abonnement actuel : {pricingPlanCode}
        <IoCheckmarkCircle color={'green'} size={'24px'} className={'ml-2'} />
      </p>
      <Button variant={'outline'} onClick={onCustomerPortalRedirection}>
        Gérer mon abonnement
      </Button>
    </div>
  );
};

export default SubscriptionManagement;
