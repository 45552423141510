import 'react-toastify/dist/ReactToastify.css';
import { Bounce, toast as toastifyToast } from 'react-toastify';

export enum ToastMessages {
  CONTACT_SUPPORT = 'Une erreur est survenue, veuillez contacter le support',
  ERROR_APPEARED = 'Une erreur est survenue',
}

type ToastVariant = 'error' | 'info' | 'success' | 'warning';
export const toast = (
  message: string,
  variant: ToastVariant = 'error',
  options = {},
) => {
  return toastifyToast[variant](message, {
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    transition: Bounce,
    position: 'bottom-right',
    theme: 'colored',
    ...options,
  });
};
