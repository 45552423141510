import React, { ReactNode, useEffect, useState } from 'react';
import { PricingPlanCode } from '@/types';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@/components/ui';
import { api } from '@/lib';
import { toast, ToastMessages } from '@/lib/toast';

interface PricingTableProps {
  currentSubscription: PricingPlanCode | null;
}

// imported https://tailgrids.com/react/components/pricing-tables
const PricingTable: React.FC<PricingTableProps> = ({ currentSubscription }) => {
  return (
    <section className="relative z-10 pb-12 lg:pb-[90px]">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-[510px] text-center">
              <h2 className="text-dark mb-3 text-3xl font-bold leading-[1.208] sm:text-4xl md:text-[40px]">
                Nos abonnements
              </h2>
            </div>
          </div>
        </div>

        <div className="-mx-4 flex flex-wrap justify-center">
          <div className="-mx-4 flex flex-wrap">
            <PricingCard
              type="Starter"
              price="49€"
              subscription="mois"
              buttonText="7 jours d'essai gratuit"
              priceId={import.meta.env.VITE_STRIPE_STARTER_PRICE_ID}
              isPricingAlreadyPurchased={
                currentSubscription === PricingPlanCode.STARTER
              }
            >
              <List>Accès à tous les filtres</List>
              <List>Accès à toutes les annonces</List>
              <List>Accès au scoring des meilleurs Ads</List>
              <List>Accès au support sous 24 heures</List>
              <List>Accès à LEAD SPY Academy (3 modules)</List>
            </PricingCard>
            <PricingCard
              type="Premium"
              price="99€"
              subscription="mois"
              buttonText="7 jours d'essai gratuit"
              priceId={import.meta.env.VITE_STRIPE_PREMIUM_PRICE_ID}
              isPricingAlreadyPurchased={
                currentSubscription === PricingPlanCode.PREMIUM
              }
            >
              <List>Accès à tous les filtres</List>
              <List>Accès à toutes les annonces</List>
              <List>Accès au scoring des meilleurs Ads</List>
              <List>Accès au support sous 24 heures</List>
              <List>Accès à LEAD SPY Academy (8 modules)</List>
              <List>Accès aux stratégies les plus performantes</List>
              <List>
                Accès aux templates créas Ads avec le plus de traction
              </List>
              <List>
                Accès aux funnels de conversion avec taux de conversion +30%
              </List>
            </PricingCard>
            <PricingCard
              type="Business"
              price="249€"
              subscription="mois"
              buttonText="7 jours d'essai gratuit"
              priceId={import.meta.env.VITE_STRIPE_BUSINESS_PRICE_ID}
              isPricingAlreadyPurchased={
                currentSubscription === PricingPlanCode.BUSINESS
              }
            >
              <List>Accès à tous les filtres</List>
              <List>Accès à toutes les annonces</List>
              <List>Accès au scoring des meilleurs Ads</List>
              <List>Accès au support sous 24 heures</List>
              <List>Accès à LEAD SPY Academy (8 modules)</List>
              <List>Accès aux stratégies les plus performantes</List>
              <List>Accès au tunnel de conversion des concurrents</List>
              <List>
                Accès aux formulaires avec scoring AI de qualification
              </List>
              <List>
                Accès aux templates créas Ads avec le plus de traction
              </List>
              <List>
                Accès aux outils de création Ads AI gratuitement en illimité
              </List>
              <List>
                Accès aux funnels de conversion avec taux de conversion +30%
              </List>
            </PricingCard>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingTable;

interface PricingCardProps {
  children: ReactNode;
  price: string;
  type: string;
  subscription: string;
  buttonText: string;
  priceId?: string;
  isPricingAlreadyPurchased: boolean;
  description?: string | null;
}
const PricingCard: React.FC<PricingCardProps> = ({
  children,
  price,
  type,
  subscription,
  buttonText,
  priceId,
  isPricingAlreadyPurchased = false,
  description = null,
}) => {
  const { user, isLoading } = useAuth0();
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(isLoading);
  useEffect(() => {
    setIsButtonLoading(isLoading);
  }, [isLoading]);

  const setPricingCardLabel = (
    isAuthenticated: boolean,
    isAlreadyPurchased: boolean,
    defaultValue: string,
  ) => {
    if (!isAuthenticated) {
      return 'Veuillez vous connecter';
    } else if (isAlreadyPurchased) {
      return 'Abonnement actif';
    }
    return defaultValue;
  };

  const setPricingCardButtonClassnames = (
    isAuthenticated: boolean,
    isAlreadyPurchased: boolean,
  ) => {
    const classnames = [
      'block w-full rounded-md border text-center text-base font-medium transition',
    ];
    if (!isAuthenticated || isAlreadyPurchased) {
      classnames.push(
        'border-stroke text-primary hover:border-primary hover:bg-primary pointer-events-none cursor-default',
      );
      classnames.push(isAlreadyPurchased ? 'bg-emerald-200' : 'bg-gray-200');
    } else {
      classnames.push(
        'border-primary bg-primary hover:bg-blue-200 hover:bg-opacity-90',
      );
    }
    return classnames.join(' ');
  };

  const redirectToCheckout = () => {
    if (priceId && user) {
      setIsButtonLoading(true);
      return api.payment
        .generatePricingLink(priceId, user.app_metadata?.stripe_customer_id)
        .then((link) => {
          window.location.href = link; // external link redirection
        })
        .catch(() => toast(ToastMessages.CONTACT_SUPPORT))
        .finally(() => {
          setIsButtonLoading(false);
        });
    }
  };

  return (
    <>
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div className="border-stroke shadow-pricing relative z-10 mb-10 overflow-hidden rounded-[10px] border-2 px-8 py-10 sm:p-12 lg:px-6 lg:py-10 xl:p-[50px]">
          <span className="text-primary mb-3 block text-lg font-semibold">
            {type}
          </span>
          <h2 className="text-dark mb-5 text-[42px] font-bold">
            {price}
            <span className="text-body-color text-base font-medium">
              / {subscription}
            </span>
          </h2>
          <p className="border-stroke text-body-color mb-8 border-b pb-8 text-base">
            {description}
          </p>
          <div className="mb-9 flex flex-col gap-[14px]">{children}</div>
          {priceId && (
            <Button
              onClick={redirectToCheckout}
              className={setPricingCardButtonClassnames(
                !!user,
                isPricingAlreadyPurchased,
              )}
              variant={'outline'}
              loading={isButtonLoading}
            >
              {setPricingCardLabel(
                !!user,
                isPricingAlreadyPurchased,
                buttonText,
              )}
            </Button>
          )}
          <div>
            <span className="absolute right-0 top-7 z-[-1]">
              <svg
                width={77}
                height={172}
                viewBox="0 0 77 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx={86} cy={86} r={86} fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1={86}
                    y1={0}
                    x2={86}
                    y2={172}
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                    <stop offset={1} stopColor="#C4C4C4" stopOpacity={0} />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span className="absolute right-4 top-4 z-[-1]">
              <svg
                width={41}
                height={89}
                viewBox="0 0 41 89"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="38.9138"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 38.9138 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 38.9138 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 38.9138 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 38.9138 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 38.9138 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 38.9138 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 38.9138 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="1.42021"
                  r="1.42021"
                  transform="rotate(180 38.9138 1.42021)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 26.4157 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 26.4157 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 26.4157 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 26.4157 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 26.4157 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 26.4157 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 26.4157 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 26.4157 1.4202)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 13.9177 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 13.9177 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 13.9177 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 13.9177 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 13.9177 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 13.9177 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 13.9177 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="1.42019"
                  r="1.42021"
                  transform="rotate(180 13.9177 1.42019)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 1.41963 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 1.41963 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 1.41963 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 1.41963 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 1.41963 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 1.41963 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 1.41963 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 1.41963 1.4202)"
                  fill="#3056D3"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

interface ListProps {
  children: ReactNode;
}
const List: React.FC<ListProps> = ({ children }) => {
  return <p className="text-body-color text-base">{children}</p>;
};
