import { Stack } from '@chakra-ui/react';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
  PopoverCloseTrigger,
} from '@/components/ui/popover';

import { useState } from 'react';

interface MultiSelectProps {
  buttonName: string;
  checkboxes: { value: string; label: string; checked: boolean }[];
  disabled?: boolean;
  isInvalid?: boolean;
  maxItems?: number;
  onChange: (selectedValues: string[]) => void;
}

export const MultiSelect: React.FC<MultiSelectProps> = ({
  buttonName,
  checkboxes,
  disabled = false,
  isInvalid = false,
  onChange,
  maxItems,
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(
    checkboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value),
  );

  const handleCheckboxChange = (value: string) => {
    const updatedValues = selectedValues.includes(value)
      ? selectedValues.filter((v) => v !== value)
      : [...selectedValues, value];

    setSelectedValues(updatedValues);
    onChange(updatedValues); // Propager les valeurs sélectionnées au parent
  };

  const isCheckboxDisabled = (value: string): boolean => {
    return !!(
      maxItems &&
      selectedValues.length >= maxItems &&
      !selectedValues.includes(value)
    );
  };
  return (
    <PopoverRoot>
      <PopoverTrigger asChild>
        <Button
          size="md"
          variant="outline"
          disabled={disabled}
          borderColor={isInvalid ? 'red' : ''}
        >
          {buttonName}
          <div
            className={`h-4 w-4 ${selectedValues.length > 0 && 'rounded-full bg-black text-xs text-white'}`}
          >
            {selectedValues.length > 0 && selectedValues.length}
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent w={200}>
        <PopoverArrow />
        <PopoverBody className={'max-h-64 overflow-y-auto'}>
          <Stack gap="4">
            {checkboxes.map((checkbox, index) => (
              <Checkbox
                key={index}
                className={'cursor-pointer'}
                checked={selectedValues.includes(checkbox.value)}
                onChange={() => handleCheckboxChange(checkbox.value)}
                disabled={isCheckboxDisabled(checkbox.value)}
                _hover={{ cursor: 'pointer' }}
              >
                {checkbox.label}
              </Checkbox>
            ))}
          </Stack>
        </PopoverBody>
        <PopoverCloseTrigger />
      </PopoverContent>
    </PopoverRoot>
  );
};
