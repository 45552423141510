import { IoClose } from 'react-icons/io5';
import { ReactNode, useContext, useState } from 'react';
import { StorageContext } from '@/providers';

interface MessageBannerProps {
  hideableKey?: string;
  variant: 'info' | 'success';
  children: ReactNode;
}

export const MessageBanner: React.FC<MessageBannerProps> = ({
  hideableKey,
  children,
  variant = 'info',
}) => {
  const { getStorage, setStorage } = useContext(StorageContext);
  const [hideBanner, setHideBanner] = useState(getStorage(hideableKey));
  const hide = () => {
    if (hideableKey) {
      setHideBanner('true');
      setStorage(hideableKey, 'true');
    }
  };

  const variants = {
    info: 'border-yellow-500 bg-yellow-50',
    success: 'border-green-500 bg-green-50',
  };

  return (
    !hideBanner && (
      <div
        className={`relative w-full rounded-md border-2 p-8 ${variants[variant]}`}
      >
        {children}
        {hideableKey && (
          <IoClose
            className={'absolute right-4 top-4 cursor-pointer'}
            onClick={() => hide()}
          />
        )}
      </div>
    )
  );
};
