import { Input, Kbd, Button } from '@chakra-ui/react';
import { InputGroup, MultiSelect, DatePicker, Field } from '@/components';
import { LuSearch } from 'react-icons/lu';
import { useState } from 'react';
import type { Error, MetaQueryParams } from '@/types';
import {
  SelectAdvertisers,
  SelectOption,
} from '@/pages/dashboard/local-components/select-advertisers';

type CheckboxFilter = {
  value: string;
  label: string;
  checked: boolean;
};

type DashboardFilter = {
  items: CheckboxFilter[];
  required?: boolean;
  label: string;
};

type FilterMetaQueryKey = Exclude<
  keyof MetaQueryParams,
  'limit' | 'advertiserPageIds'
>;
const dashboardFilters: { [key in FilterMetaQueryKey]: DashboardFilter } = {
  countries: {
    label: 'Pays',
    items: [
      { value: 'FR', label: 'France', checked: true },
      { value: 'DE', label: 'Allemagne', checked: false },
      { value: 'AT', label: 'Autriche', checked: false },
      { value: 'BE', label: 'Belgique', checked: false },
      { value: 'BG', label: 'Bulgarie', checked: false },
      { value: 'DK', label: 'Danemark', checked: false },
      { value: 'ES', label: 'Espagne', checked: false },
      { value: 'FI', label: 'Finlande', checked: false },
      { value: 'GR', label: 'Grèce', checked: false },
      { value: 'IE', label: 'Irlande', checked: false },
      { value: 'IT', label: 'Italie', checked: false },
      { value: 'LU', label: 'Luxembourg', checked: false },
      { value: 'MT', label: 'Malte', checked: false },
      { value: 'NO', label: 'Norvège', checked: false },
      { value: 'NL', label: 'Pays-Bas', checked: false },
      { value: 'PL', label: 'Pologne', checked: false },
      { value: 'PT', label: 'Portugal', checked: false },
      { value: 'CZ', label: 'République Tchèque', checked: false },
      { value: 'GB', label: 'Royaume-Uni', checked: false },
      { value: 'SE', label: 'Suède', checked: false },
      { value: 'CH', label: 'Suisse', checked: false },
    ],
    required: true,
  },
  languages: {
    label: 'Langue',
    items: [
      { value: 'fr', label: 'Français', checked: false },
      { value: 'de', label: 'Allemand', checked: false },
      { value: 'en', label: 'Anglais', checked: false },
      { value: 'bg', label: 'Bulgare', checked: false },
      { value: 'cs', label: 'Tchèque', checked: false },
      { value: 'da', label: 'Danois', checked: false },
      { value: 'es', label: 'Espagnol', checked: false },
      { value: 'et', label: 'Estonien', checked: false },
      { value: 'fi', label: 'Finnois', checked: false },
      { value: 'el', label: 'Grec', checked: false },
      { value: 'ga', label: 'Irlandais', checked: false },
      { value: 'hr', label: 'Croate', checked: false },
      { value: 'hu', label: 'Hongrois', checked: false },
      { value: 'it', label: 'Italien', checked: false },
      { value: 'lv', label: 'Letton', checked: false },
      { value: 'lt', label: 'Lituanien', checked: false },
      { value: 'mt', label: 'Maltais', checked: false },
      { value: 'nl', label: 'Néerlandais', checked: false },
      { value: 'pl', label: 'Polonais', checked: false },
      { value: 'pt', label: 'Portugais', checked: false },
      { value: 'ro', label: 'Roumain', checked: false },
      { value: 'sk', label: 'Slovaque', checked: false },
      { value: 'sl', label: 'Slovène', checked: false },
      { value: 'sv', label: 'Suédois', checked: false },
    ],
  },
  isActive: {
    label: 'Statut',
    items: [
      { value: 'ACTIVE', label: 'Active', checked: false },
      { value: 'INACTIVE', label: 'Inactive', checked: false },
    ],
  },
  formType: {
    label: 'Type de formulaire',
    items: [
      { value: 'meta', label: 'Meta', checked: false },
      { value: 'external', label: 'Externe', checked: false },
    ],
  },
  mediaType: {
    label: 'Type de media',
    items: [
      { value: 'IMAGE', label: 'Image', checked: false },
      { value: 'VIDEO', label: 'Vidéo', checked: true },
      { value: 'MEME', label: 'Meme', checked: false },
      { value: 'NONE', label: 'Aucun', checked: false },
    ],
  },
  targetAgeRanges: {
    label: "Âge d'audience",
    items: [
      { value: '18-24', label: '18-24', checked: false },
      { value: '25-34', label: '25-34', checked: false },
      { value: '35-44', label: '35-44', checked: false },
      { value: '45-54', label: '45-54', checked: false },
      { value: '55-64', label: '55-64', checked: false },
      { value: '65-100', label: '65-100', checked: false },
    ],
  },
  targetGender: {
    label: "Genre d'audience",
    items: [
      { value: 'Man', label: 'Homme', checked: false },
      { value: 'Women', label: 'Femme', checked: false },
    ],
  },
  dateMin: { label: 'Visible sur la période', required: false, items: [] },
  dateMax: { label: 'Visible sur la période', required: false, items: [] },
  keywords: { label: 'Mots clé', required: false, items: [] },
};

interface FilterListProps {
  onSearch: (selectedFilters: MetaQueryParams) => void;
}

export const FilterList: React.FC<FilterListProps> = ({ onSearch }) => {
  const [errors, setErrors] = useState<Error[]>([]);

  const dashboardFiltersKeys = Object.keys(
    dashboardFilters,
  ) as FilterMetaQueryKey[];
  // Prefill selected filters with default checked values
  const [selectedFilters, setSelectedFilters] = useState<MetaQueryParams>(
    dashboardFiltersKeys.reduce((acc, key: FilterMetaQueryKey) => {
      return {
        ...acc,
        [key]: dashboardFilters[key].items
          ? dashboardFilters[key].items
              .filter((item) => item.checked)
              .map((item) => item.value)
          : '',
      };
    }, {}) as unknown as MetaQueryParams,
  );
  const [advertiserSelectValues, setAdvertiserSelectValues] = useState<
    SelectOption[]
  >([]);

  // check if form has errors before each search
  const handleErrors = () => {
    setErrors([]);
    // retrieve required filters having no value set
    const requiredFilters = Object.entries(dashboardFilters)
      .filter(([, value]) => value.required)
      .map(([key]) => key) as FilterMetaQueryKey[];
    const unfilledRequiredFilters = requiredFilters.filter(
      (key) => !selectedFilters[key] || selectedFilters[key].length === 0,
    );
    // prepare message for each missing required filter
    const errors = unfilledRequiredFilters.map((key) => ({
      key: key,
      message: `Veuillez remplir le champ ${dashboardFilters[key].label}`,
    }));
    // Custom error management, must select keywords OR advertiser
    if (
      selectedFilters.keywords.length === 0 &&
      advertiserSelectValues.length === 0
    ) {
      errors.push({
        key: 'keywords',
        message: 'Veuillez remplir le champ Mots clés ou Annonceurs',
      });
    }
    if (errors.length) {
      setErrors(errors);
      return true;
    }
    return false;
  };

  const handleSearch = () => {
    const formHasErrors = handleErrors();
    if (!formHasErrors) {
      onSearch({
        ...selectedFilters,
        advertiserPageIds: advertiserSelectValues.map((value) => value.value),
      });
    }
  };

  const handleSelectedFiltersChange = (
    key: string,
    values?: undefined | string | string[] | SelectOption[],
  ) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [key]: values,
    }));
  };

  return (
    <div className="grid w-full grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 xl:grid-cols-6">
      <Field
        className="col-span-1 sm:col-span-2 md:col-span-3"
        w="auto"
        errorText={errors.map((error) => error.message).join('. ')}
        invalid={errors.length > 0}
      >
        <InputGroup
          className="w-full"
          flex="1"
          startElement={<LuSearch />}
          endElement={<Kbd>⌘K</Kbd>}
          minW={270}
        >
          <Input
            value={selectedFilters.keywords}
            onChange={(e) =>
              handleSelectedFiltersChange('keywords', e.target.value)
            }
            placeholder="Chercher des mots clés"
          />
        </InputGroup>
      </Field>
      <SelectAdvertisers
        values={advertiserSelectValues}
        onChange={(values) => setAdvertiserSelectValues(values)}
      />
      <MultiSelect
        buttonName={dashboardFilters.countries.label}
        checkboxes={dashboardFilters.countries.items}
        onChange={(values) => handleSelectedFiltersChange('countries', values)}
      />
      <MultiSelect
        buttonName={dashboardFilters.languages.label}
        checkboxes={dashboardFilters.languages.items}
        onChange={(values) => handleSelectedFiltersChange('languages', values)}
      />
      <MultiSelect
        buttonName={dashboardFilters.isActive.label}
        checkboxes={dashboardFilters.isActive.items}
        onChange={(values) => handleSelectedFiltersChange('isActive', values)}
        maxItems={1}
      />
      <MultiSelect
        buttonName={dashboardFilters.formType.label}
        checkboxes={dashboardFilters.formType.items}
        maxItems={1}
        onChange={(values) => handleSelectedFiltersChange('formType', values)}
      />
      <DatePicker
        label={dashboardFilters.dateMin.label}
        disabled={{ after: new Date() }}
        onChange={(from: string | undefined, to: string | undefined) => {
          handleSelectedFiltersChange('dateMin', from);
          handleSelectedFiltersChange('dateMax', to);
        }}
      />
      <MultiSelect
        buttonName={dashboardFilters.mediaType.label}
        checkboxes={dashboardFilters.mediaType.items}
        onChange={(values) => handleSelectedFiltersChange('mediaType', values)}
        maxItems={1}
      />
      <MultiSelect
        buttonName={dashboardFilters.targetAgeRanges.label}
        checkboxes={dashboardFilters.targetAgeRanges.items}
        onChange={(values) =>
          handleSelectedFiltersChange('targetAgeRanges', values)
        }
      />
      <MultiSelect
        buttonName={dashboardFilters.targetGender.label}
        checkboxes={dashboardFilters.targetGender.items}
        onChange={(values) =>
          handleSelectedFiltersChange('targetGender', values)
        }
      />
      <Button className="sm:col-span-2 md:col-span-1" onClick={handleSearch}>
        Rechercher
      </Button>
    </div>
  );
};
