import { Text, Center } from '@chakra-ui/react';
import { AdCard } from './ad-card';
import { Skeleton } from '@/components/ui/skeleton';
import { MetaAd, MetaSnapshot } from '@/types';

interface AdListProps {
  isLoading: boolean;
  ads: MetaAd[] | null;
  snapshots: MetaSnapshot[];
  onAdClick: (ad: MetaAd) => void;
}

export const AdList: React.FC<AdListProps> = ({
  isLoading,
  ads,
  snapshots,
  onAdClick,
}) => {
  const getAdSnapshot = (ad: MetaAd) => {
    return snapshots.find((snapshot) => snapshot.id === ad.id);
  };
  const getAdSnapshotVideo = (ad: MetaAd) => {
    return getAdSnapshot(ad)?.data?.video;
  };
  const getAdSnapshotPicture = (ad: MetaAd) => {
    return getAdSnapshot(ad)?.data?.images;
  };
  const getAdSnapshotMediaType = (ad: MetaAd) => {
    return getAdSnapshot(ad)?.data?.mediaType;
  };

  return (
    <div
      className={`grid gap-4 ${((ads && ads.length > 0) || isLoading) && 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4'}`}
    >
      {isLoading &&
        Array.from({ length: 10 }).map((_, index) => (
          <Skeleton key={index} rounded="xl" height={300} />
        ))}
      {!isLoading && !ads && (
        <Center w="100%">
          <Text>COMMENCEZ UNE RECHERCHE !</Text>
        </Center>
      )}
      {!isLoading && ads?.length === 0 && (
        <>
          <p className={'font-semibold'}>
            Aucun résultat correspondant à vos options de recherches n&#39;a été
            trouvé.
          </p>
          <p>
            Vérifiez l&#39;orthographe de vos mot-clés ou ré-essayez avec des
            filtres plus larges.
          </p>
        </>
      )}
      {!isLoading &&
        ads &&
        ads.map((ad, key) => (
          <AdCard
            key={key}
            title={ad.page_name}
            formType={ad.form_type}
            language={ad.languages?.[0]}
            deliveryStartTime={ad.ad_delivery_start_time}
            totalReached={ad.eu_total_reach}
            websiteUrl={ad.ad_creative_link_captions?.[0]}
            imageUrls={getAdSnapshotPicture(ad)}
            videoUrl={getAdSnapshotVideo(ad)}
            isActive={ad.isActive}
            durationDays={ad.activeDays}
            mediaType={getAdSnapshotMediaType(ad)}
            onClick={() => onAdClick(ad)}
          />
        ))}
    </div>
  );
};
