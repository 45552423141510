import {
  MenuContent,
  MenuItem,
  MenuItemGroup,
  MenuRoot,
  MenuSeparator,
  MenuTrigger,
} from '@/components/ui';
import { IconButton, useBreakpointValue } from '@chakra-ui/react';
import { RxHamburgerMenu } from 'react-icons/rx';
import { ROUTES } from '@/types';
import { useAuth0, User } from '@auth0/auth0-react';

interface MenuBurgerProps {
  user?: User;
}

export const MenuBurger: React.FC<MenuBurgerProps> = ({ user }) => {
  const { loginWithRedirect, logout } = useAuth0();
  const isVisibleBurger = useBreakpointValue({ base: true, xl: false });

  if (!isVisibleBurger) return null;

  return (
    <MenuRoot>
      <MenuTrigger asChild>
        <IconButton variant="outline" rounded="full" size="xl">
          <RxHamburgerMenu />
        </IconButton>
      </MenuTrigger>
      <MenuContent>
        <MenuItemGroup title="Mon compte">
          {user ? (
            <>
              <MenuItem value="welcome" className={'cursor-default'}>
                Bienvenue {user.given_name} !
              </MenuItem>
              <MenuItem value="signout" className={'cursor-pointer'}>
                <a
                  onClick={() =>
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    })
                  }
                >
                  Se déconnecter
                </a>
              </MenuItem>
            </>
          ) : (
            <>
              <MenuItem value="signin" className={'cursor-pointer'}>
                <a onClick={() => loginWithRedirect()}>Se connecter</a>
              </MenuItem>
              <MenuItem value="signup" className={'cursor-pointer'}>
                <a
                  onClick={() =>
                    loginWithRedirect({
                      authorizationParams: { screen_hint: 'signup' },
                    })
                  }
                >
                  S&#39;inscrire
                </a>
              </MenuItem>
            </>
          )}
        </MenuItemGroup>
        <MenuSeparator />
        <MenuItemGroup title="Navigation">
          <MenuItem value="dashboard">
            <a href={ROUTES.DASHBOARD}>Tableau de bord</a>
          </MenuItem>
          <MenuItem value="upgrade">
            <a href={ROUTES.PLANS}>Abonnements</a>
          </MenuItem>
        </MenuItemGroup>
      </MenuContent>
    </MenuRoot>
  );
};
