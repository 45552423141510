import {
  MetaAd,
  MetaPage,
  MetaAdFromApi,
  MetaQueryParams,
  MetaSnapshot,
  Snapshot,
  SnapshotCard,
  SnapshotImage,
  SnapshotVideo,
  MediaType,
} from '@/types/meta';
import { toast, ToastMessages } from '@/lib/toast';

export class Meta {
  private readonly apiUrl?: string;
  private readonly adsApiUrl?: string;
  private readonly scrapperUrl?: string;
  constructor() {
    this.apiUrl = import.meta.env.VITE_API_BASE_URL;
    this.scrapperUrl = import.meta.env.VITE_SCRAPPER_BASE_URL;
    this.adsApiUrl = import.meta.env.VITE_ADS_API_BASE_URL;
  }

  /**
   * Get the meta ads from the api
   * @param filters filters to apply to the query
   * @returns the meta ads filtered by the filters
   */
  getMetaData = async (filters: MetaQueryParams): Promise<MetaAd[]> => {
    const formattedFilters: Record<string, string> = Object.fromEntries(
      Object.entries(filters)
        .filter(([, value]) => value && value?.length)
        .map(([key, value]) => {
          return [key, Array.isArray(value) ? value.join(',') : value];
        }),
    ) as unknown as Record<string, string>;
    const url =
      this.apiUrl +
      '/meta/fetch?' +
      new URLSearchParams(formattedFilters).toString();

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) =>
        response
          .json()
          .then((json) =>
            json.data.map(
              (item: MetaAdFromApi): MetaAd => this.parseMetaAd(item),
            ),
          ),
      )
      .catch((error) => {
        console.error('API Call Error', error);
        toast(ToastMessages.CONTACT_SUPPORT, 'error');
        return [];
      });
  };
  /**
   * Get Meta pages from an advertiser keyword
   * @param name Name of the advertiser
   * @returns returns a list of pages matching to the advertiser name
   */
  getAdvertiserPages = async (name: string): Promise<MetaPage[]> => {
    return fetch(this.adsApiUrl + '/meta/advertisers?query=' + name, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json().then((json: MetaPage[]) => json));
  };
  /**
   * Get a snapshot of an ad
   * @param id id of the ad
   * @param snapshotUrl url of the snapshot
   * @returns returns the snapshot of the ad
   */
  getMetaSnapshot = (
    id: string,
    snapshotUrl: string,
  ): Promise<MetaSnapshot> => {
    return fetch(
      this.scrapperUrl + '/api/snapshot?url=' + encodeURIComponent(snapshotUrl),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
      .then((response) =>
        response.json().then((json) => {
          const video = this.getVideoUrl(json?.snapshot);

          const images = [
            ...this.getResizedImageUrls(json?.snapshot?.cards),
            ...this.getResizedImageUrls(json?.snapshot?.images),
          ].slice(0, 10);

          const mediaType = this.getMediaType(video, images);

          return { id: id, data: { video, images, mediaType } };
        }),
      )
      .catch((error) => {
        console.error('API Call Error', error);
        return { id: id, data: null };
      });
  };

  /**
   * DTO function to parse the data from the api
   * @param rawMetaAd data from the api
   * @returns a MetaAd object used in the app
   */
  parseMetaAd = (rawMetaAd: MetaAdFromApi): MetaAd => {
    const ad_delivery_start_time_parsed = this.parseUTCDate(
      rawMetaAd.ad_delivery_start_time,
    );
    const ad_delivery_stop_time_parsed = this.parseUTCDate(
      rawMetaAd.ad_delivery_stop_time,
    );
    const activeDays = this.activeDurationDays(
      ad_delivery_start_time_parsed,
      ad_delivery_stop_time_parsed,
    );

    return {
      ...rawMetaAd,
      ad_delivery_start_time: ad_delivery_start_time_parsed,
      ad_delivery_stop_time: ad_delivery_stop_time_parsed,
      isActive: this.isActive(ad_delivery_stop_time_parsed),
      activeDays: activeDays,
    };
  };

  /**
   * Get the first video url from the snapshot
   * We do not manage several videos for the moment
   * @param snapshot
   * @returns the first video url from the snapshot
   */
  getVideoUrl = (snapshot: Snapshot) =>
    snapshot?.videos?.[0] || snapshot?.cards?.[0];

  /**
   * Get the imagege urls from the snapshot depend of
   * the type of the snapshot
   * @param snapshotImage
   * @returns the resized image urls
   */
  getResizedImageUrls = (
    snapshotImage?: (SnapshotCard | SnapshotImage)[],
  ): string[] =>
    snapshotImage
      ?.filter((item) => item.resized_image_url)
      .map((item) => item.resized_image_url || '') || [];

  private getMediaType = (
    video?: SnapshotVideo,
    images?: string[],
  ): MediaType => {
    if (video && video.video_sd_url) return 'video';
    if (images?.length && images.length > 1) return 'carousel';
    if (images?.length && images.length === 1) return 'image';
    return 'unknown';
  };

  /**
   * Take the stopDeliveryDate and check if the ad is active
   * This date is always undefined when the ad is active
   * THis date can be in the future, in which case the ad is active
   * @param stopDeliveryDate the date when the ad should stop being delivered
   * @returns true if the ad is active, false otherwise
   */
  isActive = (stopDeliveryDate?: Date): boolean => {
    if (!stopDeliveryDate) return true;
    const today = new Date();
    return today < stopDeliveryDate;
  };

  /**
   *
   * @param deliveryStartTime - UTC date format "YYYY-mm-dd"
   * @param deliveryStopTime - UTC date format "YYYY-mm-dd"
   * @returns The difference in days between the delivery start time and the delivery stop time
   */
  activeDurationDays = (
    deliveryStartTime?: Date,
    deliveryStopTime?: Date,
  ): number | undefined => {
    if (!deliveryStartTime) return undefined;

    // Parse the delivery start date
    const deliveryStartDate = new Date(deliveryStartTime);
    if (isNaN(deliveryStartDate.getTime())) return undefined;

    // Parse the delivery stop date
    let deliveryStopDate = deliveryStopTime
      ? new Date(deliveryStopTime)
      : new Date();
    if (isNaN(deliveryStopDate.getTime())) return undefined;

    // Use deliveryStopDate if it's in the past, otherwise use today's date
    const today = new Date();
    deliveryStopDate = +deliveryStopDate < +today ? deliveryStopDate : today;

    // Calculate the difference in days
    const diff = deliveryStopDate.getTime() - deliveryStartDate.getTime();
    const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));

    return diffDays;
  };

  /**
   * Parse a date string in UTC format to a Date object
   * @param dateString the date to parse
   * @returns the parsed date if it is valid, undefined otherwise
   */
  parseUTCDate = (dateString: string): Date | undefined => {
    const parsedDate = new Date(dateString);
    return isNaN(parsedDate.getTime()) ? undefined : parsedDate;
  };
}
